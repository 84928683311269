import SidePopup from "../SidePopup";
import ContactUs from "./ContactUs";
import Htf from "./Artists";
import Projects from "./Projects";
import Search from "./Search";
import Wtf from "./Wtf";

const SidePopups = (props) => {
  return props.data.map((el, index) => {
    const componentToRender = () => {
      switch (props.active) {
        case "wtf":
          return <Wtf  {...props} />;
        case "projects":
          return <Projects   {...props} />;
        case "htf":
          return <Htf  {...props} />;
        case "contact":
          return <ContactUs  {...props} />;
        case "search":
          return <Search  {...props} />;
        default:
          return "";
      }
    };
    return (
      <SidePopup key={index} {...props} active={props.active == el} activePlanet={el}>
        {componentToRender()}
      </SidePopup>
    );
  });
};
export default SidePopups;
