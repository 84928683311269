
import React, { useContext } from 'react';
import DataContext from '../../../contexts/DataContext';

const TwIcon = props => {
    const { fixedTitles } = useContext(DataContext)
    return (
        <div className="tw-icon icons-row pointer">
            <a href={props.link} target="_blank" className=" d-block " rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="32.58" height="32.588" viewBox="0 0 32.58 32.588">
                    <path id="Path_137" data-name="Path 137" d="M5842,2176.443a16.3,16.3,0,1,0,4.772,11.528A16.267,16.267,0,0,0,5842,2176.443Zm-4.343,7.911v.469a9.985,9.985,0,0,1-.244,2.2,10.29,10.29,0,0,1-10.274,8.154,10.669,10.669,0,0,1-5.67-1.633c.3.029.586.049.889.049a7.483,7.483,0,0,0,4.585-1.554,3.729,3.729,0,0,1-3.451-2.542,3.293,3.293,0,0,0,.694.078,3.683,3.683,0,0,0,.978-.136,3.689,3.689,0,0,1-2.718-2.249,3.443,3.443,0,0,1-.254-1.31v-.049a3.58,3.58,0,0,0,1.671.45,3.592,3.592,0,0,1-1.134-4.859,10.5,10.5,0,0,0,7.626,3.8,3.827,3.827,0,0,1-.108-.831,3.714,3.714,0,0,1,6.4-2.483,7.563,7.563,0,0,0,2.356-.89,3.644,3.644,0,0,1-1.633,2.023,7.887,7.887,0,0,0,2.121-.576A7.662,7.662,0,0,1,5837.652,2184.354Z" transform="translate(-5814.187 -2171.672)" fill="#fff" />
                </svg>
            </a>
        </div>
    )
}
export default TwIcon;