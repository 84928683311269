import React from "react";
import CloseBtn from "../components/close-btn";
const SidePopup = (props) => {
  return (
    <div
      className={
        "side-popup  " + (props.active ? ` show ${props.activePlanet}` : "")
      }
    // onTransitionEnd={_handleTransitionEnd}
    >
      <div className="container-fluid fh-100 ">
        <div className="row fh-100 ">{props.children}</div>
      </div>
      <div className="side-popup-close-container">
        <CloseBtn
          togglePopup={() => {
            props.setSidePopup("");
          }}
          black={true}
        />
      </div>
    </div>
  );
};
export default SidePopup;
