import React from 'react';

const Planets = props => {
    return (
        <div className="planets-icon icon-row pointer " onClick={props.onClick}>

            <svg xmlns="http://www.w3.org/2000/svg" width="50.061" height="29.5" viewBox="0 0 50.061 29.5">
                <path fill="#fff" id="Path_328" data-name="Path 328" d="M1872.17,119.015c-.647-2.484-3.722-3.534-9.671-3.3-1.232.048-2.557.155-3.949.31a14.74,14.74,0,0,0-25.782,6.71c-1.293.543-2.5,1.1-3.6,1.655-5.311,2.7-7.483,5.11-6.837,7.593.588,2.259,3.182,3.33,8.123,3.33q.74,0,1.548-.032c1.232-.048,2.557-.155,3.949-.31a14.74,14.74,0,0,0,25.782-6.71c1.293-.543,2.5-1.1,3.6-1.655C1870.644,123.911,1872.816,121.5,1872.17,119.015ZM1826.5,130.9c.746-.843,2.772-2.2,6.1-3.728a14.687,14.687,0,0,0,1.036,4.01C1829.988,131.467,1827.562,131.272,1826.5,130.9Zm35.4-7.072a14.66,14.66,0,0,0-1.037-4.011c3.649-.286,6.076-.09,7.138.283C1867.254,120.943,1865.228,122.3,1861.9,123.828Z" transform="translate(-1822.219 -110.75)" />
            </svg>

        </div>
    )
}
export default Planets;