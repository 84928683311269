import React, { useState, useEffect } from 'react';
import CloseBtn from '../components/close-btn';
const BlackPopup = props => {
    const [active, setActive] = useState(props.active);
    useEffect(() => {
        setActive(props.active);
    }, [props.active]);
    return <div className={"black-popup  " + (active ? ' show ' : '')}>
        <CloseBtn className={`${props.closable ? '' : 'pe-none'}`} togglePopup={() => {
            props.togglePopup();
        }} />
        {active ? props.children : ""}
    </div>
    return <div></div>
}

export default BlackPopup;