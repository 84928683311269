import React, { useState, useEffect, useContext } from "react";
import Project from "./Project";
import Loader from "react-loader-spinner";
import BlackPopup from "./BlackPopup";
import ProjectInformation from "./ProjectInformation";
import DataContext from "../contexts/DataContext";
import { unstable_batchedUpdates } from "react-dom";
const ProjectGrid = (props) => {
    const { fixedTitles } = useContext(DataContext);
    const axios = require("axios");
    const [loadMoreLoader, setLoadMoreLoader] = useState(false);
    const [projects, setProjects] = useState([]);
    const [lastPage, setLastPage] = useState(1);
    const [page, setPage] = useState(1);
    const [currentProjectIndex, setCurrentProjectIndex] = useState();
    const [closable, setClosable] = useState(true);
    const _handleProjectFetching = (page2, callback) => {
        setLoadMoreLoader(true)
        axios.get(`projects?page=${page2}${props.artist ? `&artist=${props.artist.id}` : ""}`).then(function (response) {
            unstable_batchedUpdates(() => {
                setLoadMoreLoader(false);
                if (props.artist && props.artist.id)
                    setProjects(response.data.projects.data);
                else
                    setProjects([...projects, ...response.data.projects.data]);
                setLastPage(response.data.projects.last_page);
                setPage(page2);
            })
            if (callback)
                callback();
        });
    }
    useEffect(() => {
        _handleProjectFetching(1);
    }, []);
    useEffect(() => {
        if (props.artist && props.artist.id)
            _handleProjectFetching(1);
    }, [props.artist]);
    return <>
        <div
            className={
                (currentProjectIndex !== undefined && currentProjectIndex != null ? " stack-front " : "") + " project-black "
            }
        >
            <BlackPopup
                togglePopup={() => {
                    setCurrentProjectIndex();
                    if (props.setClosable)
                        props.setClosable(true)
                }}
                closable={closable}
                active={currentProjectIndex !== undefined && currentProjectIndex != null}
            >
                <ProjectInformation
                    project={projects[currentProjectIndex]}
                    projectIndex={currentProjectIndex}
                    setCurrentProjectIndex={setCurrentProjectIndex}
                    allProjects={projects}
                    page={page}
                    lastPage={lastPage}
                    loadMore={_handleProjectFetching}
                    setClosable={setClosable}
                />
            </BlackPopup>
        </div>
        <div className={`mt-3  scrollview ${props.className ? props.className : ""}`} >
            <div className="pr-xl-4">
                <div className="row custom-row ">
                    {loadMoreLoader && !projects.length && page == 1 ?
                        <div className="d-flex justify-content-center align-items-center w-100"> <Loader
                            type="Oval"
                            color="#00BFFF"
                            visible={true}
                            height={50}
                            width={50} /></div> :
                        projects?.map(function (project, i) {
                            return (
                                <Project
                                    className={props.colClassName}
                                    key={project.id}

                                    toggleClick={() => {
                                        setCurrentProjectIndex(i);
                                        if (props.setClosable)
                                            props.setClosable(false);
                                    }}
                                    project={project}
                                />
                            );
                        })}
                </div>
                {props.loadMore !== false && lastPage != page && projects.length ?
                    <div className="d-flex justify-content-center load-more mt-4">
                        <button onClick={() => _handleProjectFetching(page + 1)} className="btn btn-outline-dark pl-5 pr-5 d-flex align-items-center">
                            <Loader
                                type="Oval"
                                color="#00BFFF"
                                visible={loadMoreLoader}
                                height={25}
                                width={25}
                            />
                            {fixedTitles.titles['project-load-more'].title}</button>
                    </div> : ""}
            </div>
        </div>
    </>
}
export default ProjectGrid;