import React, { useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import GLTFModal from "./GLTFModal";
const GLTFGROUP = (props) => {
  const group = React.useRef();

  const [satPosition, setSatPosition] = useState(0)
  const [earthPosition, setEarthPosition] = useState(0)

  useEffect(() => {
    if (window.innerWidth > 2200) {
      setSatPosition(1.2 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 2100) {
      setSatPosition(1.2 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 2000) {
      setSatPosition(1.25 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 1900) {
      setSatPosition(1.3 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 1800) {
      setSatPosition(1.3 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 1700) {
      setSatPosition(1.2 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 1600) {
      setSatPosition(1.35 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 1500) {
      setSatPosition(1.5 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 1400) {
      setSatPosition(1.65 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 1300) {
      setSatPosition(1.72 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 1200) {
      setSatPosition(1.8 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 1100) {
      setSatPosition(1.95 * (window.innerWidth / window.innerHeight));
    } else {
      setSatPosition(2.05 * (window.innerWidth / window.innerHeight));
    }
  }, [window.innerWidth, window.innerHeight])

  useEffect(() => {
    if (window.innerWidth > 2200) {
      setEarthPosition(2.49 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 2100) {
      setEarthPosition(2.47 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 2000) {
      setEarthPosition(2.46 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 1900) {
      setEarthPosition(2.45 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 1800) {
      setEarthPosition(2.45 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 1700) {
      setEarthPosition(2.44 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 1600) {
      setEarthPosition(2.43 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 1500) {
      setEarthPosition(2.42 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 1400) {
      setEarthPosition(2.41 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth > 1300) {
      setEarthPosition(2.4 * (window.innerWidth / window.innerHeight));
    } else if (window.innerWidth >= 1200) {
      setEarthPosition(2.35 * (window.innerWidth / window.innerHeight));
    }
  }, [window.innerWidth, window.innerHeight])

  useFrame(() => {
    if (!props.rotation) return null;
    
    /* INITIATE SATELLITE'S ROTATION ON LOAD */
    group.current.rotation.z += props.rotation[1];

    if (props.sidePopup == "htf" || props.sidePopup == "contact") {
      group.current.rotation.z = 0;
      group.current.position.x = 0;
    }
    if (
      (props.sidePopup !== "htf" || props.sidePopup !== "contact") &&
      !props.goLeft
    ) {
      group.current.rotation.z += props.rotation[1];
    }
  });

  return (
    <group ref={group}>
      <GLTFModal
        path="/gltf/TerrainPlanet.gltf"
        goIn={props.goIn}
        isLoading={props.isLoading1}
        aniamtePosition={[
          earthPosition,
          0,
          0,
        ]}
        navigatingWithArrows={props.navigatingWithArrows}
        animatePlanet={props.sidePopup == "htf" ? true : false}
        position={[0, 0, 3]}
        rotation={[0, 0, 0]}
        rotationSpeed={[0, 0.0019, 0]}
        sidePopup={props.sidePopup}
        goLeft={props.sidePopup !== "htf"}
        isSidePopupOpen={props.isSidePopupOpen}
        onPointerDown={props.onPointerDown1}
        planetType="htf"
        scaleUpPlanets={props.scaleUpPlanets}
        maxScale={5}
      />
      <GLTFModal
        path="/gltf/SatellitePlanet.gltf"
        navigatingWithArrows={props.navigatingWithArrows}
        goIn={props.goIn}
        isLoading={props.isLoading1}
        sat={true}
        animatePlanet={props.sidePopup == "contact" ? true : false}
        sidePopup={props.sidePopup}
        isSidePopupOpen={props.isSidePopupOpen}
        scaleUpPlanets={props.scaleUpPlanets}
        aniamtePosition={[
          satPosition,
          0,
          0,
        ]}
        goLeft={props.sidePopup !== "contact"}
        position={[1, 0.8, 3]}
        maxScale={5}
        rotation={[2, -0.2, 0.4]}
        rotationSpeed={[0, 0, 0.0019]}
        planetType="contact"
        onPointerDown={props.onPointerDown2}
      />
    </group>
  );
};

export default GLTFGROUP;
