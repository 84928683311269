import React, { useContext } from "react";
import wtfIcon from "../../assets/icons/wtf.svg";
import DataContext from "../../contexts/DataContext";
const Wtf = () => {
  const { fixedTitles } = useContext(DataContext);
  return (
    <div className="col-lg-12 px-0 fh-100  content-wtf">
      <div className="white-box"></div>
      <div className="box-container w-100  pos-z scrollview">
        <img src={fixedTitles.titles['what-the-f-side-popup'].formatted_image} className="pop-title" alt="" />
        <div className="mt-70 pb-4 text-4d ">
          <div className="fs-14" dangerouslySetInnerHTML={{ __html: fixedTitles.titles['what-the-fuck'].text }}>
          </div>
        </div>
        <footer>
          <div className="d-flex align-items-center">
            <img src={fixedTitles.titles['mophradat'].formatted_image} className="mophradat-logo" />
            <small>
              {fixedTitles.titles['mophradat'].title}
            </small>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Wtf;
