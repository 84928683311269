import React from 'react';

const CircleIcon = props => {
    return (
        <div className="circle-icon icon-row pointer" onClick={props.onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="29.633" height="29.633" viewBox="0 0 29.633 29.633">
                <g id="Group_129" data-name="Group 129" transform="translate(-5674.02 -1196.34)">
                    <path id="Path_133" data-name="Path 133" d="M5688.837,1196.34a14.817,14.817,0,1,0,14.816,14.816A14.817,14.817,0,0,0,5688.837,1196.34Zm0,20.484a5.668,5.668,0,1,1,5.668-5.668A5.668,5.668,0,0,1,5688.837,1216.824Z" fill="#fff" />
                    <circle id="Ellipse_60" data-name="Ellipse 60" cx="2.801" cy="2.801" r="2.801" transform="matrix(0.987, -0.16, 0.16, 0.987, 5685.623, 1208.84)" fill="#fff" />
                </g>
            </svg>
        </div>
    )
}
export default CircleIcon;