import React, { useContext } from 'react';
import DataContext from '../../../contexts/DataContext';

const FbIcon = props => {
    const { fixedTitles } = useContext(DataContext)
    return (
        <div className="fb-icon  icons-row pointer ">
            <a href={props.link} target="_blank" className="d-block" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="32.58" height="32.588" viewBox="0 0 32.58 32.588">
                    <path id="Path_136" data-name="Path 136" d="M5862.01,2124.771a16.3,16.3,0,1,0,4.772,11.528A16.266,16.266,0,0,0,5862.01,2124.771Zm-7.519,10.1-.118,1.417H5851.7v9.014H5848.1v-9.014h-1.819v-3.607h1.8v-1.623a3.5,3.5,0,0,1,3.793-3.774h2.787v3.09h-2.024a.758.758,0,0,0-.792.86v1.447h2.865Z" transform="translate(-5834.202 -2120)" fill="#fff" />
                </svg>
            </a>
        </div>
    )
}
export default FbIcon;