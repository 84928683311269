import React, { useState, useEffect, useContext } from "react";
import Artist from "../Artist";
import SearchIcon from "../icons/search";
import DownArrow from "../../assets/icons/arrow-sm-down.svg";
import Check from "../../assets/icons/check.svg";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import BlackPopup from "../BlackPopup";
import ArtistInfo from "../ArtistInfo";
import DataContext from "../../contexts/DataContext";
import { unstable_batchedUpdates } from "react-dom";

const axios = require("axios");

const Artists = (props) => {
  const [artists, setArtists] = useState();
  const [nameSort, setNameSort] = useState("a-z");
  const [nameSortOpen, setNameSortOpen] = useState(false);
  const [tagsFilter, setTagsFilter] = useState([]);
  const [tagsFilterOpen, setTagsFilterOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [searchValue, setSearchValue] = useState();
  const [searchLoader, setSearchLoader] = useState(false);
  const [loadMoreLoader, setLoadMoreLoader] = useState(false);
  const [activeArtistIndex, setActiveArtistIndex] = useState();
  const [initialLoader, setInitialLoader] = useState(true);
  const [closable, setClosable] = useState(true);
  const { fixedTitles } = useContext(DataContext);
  let counter = 0;
  const _handleArtistFetching = (page, callback) => {
    if (page !== 1) {
      setLoadMoreLoader(true)
    }
    axios.get(`artists?page=${page}${nameSort ? '&sortBy=' + nameSort : ""}${tagsFilter.length ? '&filterTags=' + tagsFilter.join(',') : ""}${searchValue ? '&search=' + searchValue : ""}`).then(function (response) {
      unstable_batchedUpdates(() => {
        if (page != 1)
          setArtists([...artists, ...response.data.artists.data]);
        else
          setArtists(response.data.artists.data);
        setLastPage(response.data.artists.last_page);
        setPage(page);
        setLoadMoreLoader(false);
        setInitialLoader(false);
      })
      if (callback)
        callback();
    });
  }
  const _handleSearchChange = (e) => {
    setSearchValue(e.target.value)
  }
  const _handleSearch = (e) => {
    e.preventDefault();
    _handleArtistFetching(1);
  }
  useEffect(() => {
    _handleArtistFetching(1);
  }, [nameSort, tagsFilter]);
  useEffect(() => {
    if (tagsFilterOpen)
      setNameSortOpen(false);
    if (nameSortOpen)
      setTagsFilterOpen(false);
  }, [tagsFilterOpen, nameSortOpen]);
  useEffect(() => {
    if (searchValue == "")
      _handleArtistFetching(1);
  }, [searchValue]);

  const _handleFilterClick = (filter, slug, setter) => {
    if (filter.includes(slug)) {
      setter(filter.filter(el => el != slug));
    }
    else {
      setter([...filter, slug])
    }
  }
  return (
    <>
      <div
        className={
          (activeArtistIndex !== undefined && activeArtistIndex !== null && activeArtistIndex !== -1 ? " stack-front " : "") + " artists-black "
        }
      >
        <BlackPopup
          togglePopup={() => {
            setActiveArtistIndex(-1);
          }}
          closable={closable}
          active={activeArtistIndex !== undefined && activeArtistIndex !== null && activeArtistIndex !== -1}
        >
          <ArtistInfo
            activeArtistIndex={activeArtistIndex}
            allArtists={artists}
            setActiveArtistIndex={setActiveArtistIndex}
            page={page}
            lastPage={lastPage}
            loadMore={_handleArtistFetching}
            setClosable={setClosable}
          />
        </BlackPopup>
      </div>
      <div className="col-xl-12 px-0  fh-100 ">
        <div className="white-box"></div>
        <div className="box-container-sm w-100  pos-z">
          <div className="row">
            <div className="col-md-3 text-md-left mb-3 mb-md-0">
              <img src={fixedTitles.titles['who-the-f-side-popup'].formatted_image} className="pop-title" alt="" />
            </div>
            <div className="col-md-9 text-center text-md-right mt-5 mt-sm-0">
              <div className="search-hdf">
                <div className="d-flex justify-content-center justify-content-md-end align-items-center">
                  <SearchIcon />
                  <form onSubmit={_handleSearch}>
                    <input
                      type="text"
                      className="ml-2"
                      placeholder={fixedTitles.titles['looking-for-someone-special'].title}
                      onChange={_handleSearchChange}
                    />
                  </form>
                </div>
              </div>
              <div className="d-flex mt-3 flex-wrap justify-content-center justify-content-md-end">
                <div className={" mt-3 position-relative mr-4"}>
                  <div onClick={() => setNameSortOpen(!nameSortOpen)}>
                    <p className="fw-medium text-black pointer">
                      {fixedTitles.titles['filter-by-horoscopes'].title}  <img src={DownArrow} alt="" />{" "}
                    </p>
                  </div>
                  <div
                    className={
                      " filter-box  d-inline-block text-left" +
                      (nameSortOpen ? " filter-show " : " ")
                    }
                  >
                    <div
                      key={fixedTitles.titles['a-z'].slug}
                      className={
                        "filter-item  pointer  text-80 py-2 px-3 fs-14 fw-medium " +
                        (nameSort == fixedTitles.titles['a-z'].slug ? " active " : "")
                      }
                      onClick={() => {
                        setNameSort(fixedTitles.titles['a-z'].slug)
                        setNameSortOpen(false);
                      }}
                    >
                      <img src={Check} className="mr-3" alt="" />
                      <span>{fixedTitles.titles['a-z'].title}</span>
                    </div>

                    <div
                      key={fixedTitles.titles['z-a'].slug}
                      className={
                        "filter-item  pointer  text-80 py-2 px-3 fs-14 fw-medium " +
                        (nameSort == fixedTitles.titles['z-a'].slug ? " active " : "")
                      }
                      onClick={() => {
                        setNameSort(fixedTitles.titles['z-a'].slug)
                        setNameSortOpen(false);
                      }}
                    >
                      <img src={Check} className="mr-3" alt="" />
                      <span>{fixedTitles.titles['z-a'].title}</span>
                    </div>
                  </div>
                </div>
                <div className={" mt-3 position-relative"}>
                  <div onClick={() => setTagsFilterOpen(!tagsFilterOpen)}>
                    <p className="fw-medium text-black pointer">
                      {fixedTitles.titles['filter-by-creative-fields'].title} <img src={DownArrow} alt="" />{" "}
                    </p>
                  </div>
                  <div
                    className={
                      " filter-box  d-inline-block text-left" +
                      (tagsFilterOpen ? " filter-show " : " ")
                    }
                  >
                    {fixedTitles.tags.map(filter => <div
                      key={filter.id}
                      className={
                        "filter-item  pointer  text-80 py-2 px-3 fs-14 fw-medium " +
                        (tagsFilter.includes(filter.slug) ? " active " : "")
                      }
                      onClick={() => {
                        _handleFilterClick(tagsFilter, filter.slug, setTagsFilter)
                        setTagsFilterOpen(false);
                      }}
                    >
                      <img src={Check} className="mr-3" alt="" />
                      <span>{filter.title}</span>
                    </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-4 pb-70 scrollview px-3 pl-md-0 pr-md-5">
            {searchLoader || initialLoader ? <div className="d-flex justify-content-center align-items-center">  <Loader
              type="Oval"
              color="#00BFFF"
              visible={true}
              height={50}
              width={50} /></div> :
              <div className="row">
                {artists?.map(function (profile, i) {
                  let color = "blue";
                  if (counter === 1 || counter === 2) {
                    color = "red";
                  }
                  if (counter === 2) {
                    counter = 0;
                  } else {
                    counter = counter + 1;
                  }
                  return (
                    <Artist
                      type={color}
                      key={i.toString()}
                      toggleClick={() => {
                        setActiveArtistIndex(i);
                        // props.setMenuOpen(false);
                        // props.setIsSidePopupOpen(false);
                      }}
                      page={page}
                      lastPage={lastPage}
                      {...profile}
                    />
                  );
                })}
              </div>
            }
            {lastPage != page ?
              <div className="d-flex justify-content-center load-more">
                <button onClick={() => _handleArtistFetching(page + 1)} className="btn btn-outline-dark pl-5 pr-5 d-flex align-items-center">
                  <Loader
                    type="Oval"
                    color="#00BFFF"
                    visible={loadMoreLoader}
                    height={25}
                    width={25}
                  />
                  {fixedTitles.titles['artist-load-more'].title}</button>
              </div> : ""}
          </div>
        </div>
      </div>
    </>
  );
};

export default Artists;
