import ProjectGrid from "../ProjectsGrid";
import React, { useContext } from "react";
import DataContext from "../../contexts/DataContext";

const Projects = (props) => {
  const { fixedTitles } = useContext(DataContext);
  return (
    <>
      <div className="col-lg-12 px-0  fh-100 ">
        <div className="white-box"></div>
        <div className="box-container-0 w-100  pos-z">
          <img src={fixedTitles.titles['explore-projects'].formatted_image} className="pop-title" alt="" />
          <ProjectGrid className="py-70" colClassName={'col-lg-4'} />
        </div>
      </div >
    </>
  );
};

export default Projects;
