import "./App.css";
import "./assets/css/bootstrap.min.css";
import "./assets/fonts/stylesheet.css";
import "./styles.css";
import DataContext from "./contexts/DataContext";
import React, { useState, useEffect, useRef } from "react";
import Menu from "./components/Menu";
import { Canvas, extend } from "@react-three/fiber";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import axios from "axios";
import Logo from "./components/icons/logo";
import Scene from "./components/Scene";
import SidePopups from "./components/SideAreas/SidePopups";

extend({ OrbitControls });
// const BASE_URL = "https://wormhole-backend.hellotree.dev/api";
const BASE_URL = "https://wormholecollective.com/api";
axios.defaults.baseURL = BASE_URL;

function App() {
  // document.addEventListener('contextmenu', (e) => { e.preventDefault(); return false });
  document.addEventListener('dragstart', (e) => {
    e.preventDefault()
  });

  const [sidePopup, setSidePopup] = useState();
  const [search, setSearch] = useState();
  const [isSidePopupOpen, setIsSidePopupOpen] = useState(false);
  const [activePopupOnWee, setActivePopupOnWee] = useState();
  const [currentTeam, setCurrentTeam] = useState("");
  const [currentProject, setCurrentProject] = useState("");
  const [allTeams, setAllTeams] = useState();
  const [allProjects, setAllProjects] = useState();
  const [weee, setWeee] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [stars, setStars] = useState([]);
  const [hideLogo, setHideLogo] = useState(false);
  const [fadeOutLogo, setFadeOutLogo] = useState(false);
  const [refreshAnimation, setRefreshAnimation] = useState(false);
  const [initialCameraReset, setInitialCameraReset] = useState(false);
  const [windowLoaded, setWindowLoaded] = useState(false);
  const [window1Loaded, setWindow1Loaded] = useState(false);
  const [navigatingWithArrows, setNavigatingWithArrows] = useState();
  const [p1, setP1] = useState(false);
  const [p2, setP2] = useState(false);
  const [p3, setP3] = useState(false);
  const [p4, setP4] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState("Loading");
  const hole = useRef();

  const [goIn, setGoIn] = useState(false);
  const [fadeOutHole, setFadeOutHole] = useState(false);
  const [hideHole, setHideHole] = useState(false);
  const [refreshWee, setRefreshWee] = useState(null);
  const [scaleUpPlanets, setScaleUpPlanets] = useState(false);
  const [fixedTitles, setFixedTitles] = useState();
  useEffect(() => {
    if (sidePopup) setIsSidePopupOpen(true);
    setInitialCameraReset(!initialCameraReset);
    setMenuOpen(sidePopup ? true : false);
  }, [sidePopup]);

  useEffect(() => {
    if (goIn) {
      var loader = new THREE.TextureLoader();
      loader.crossOrigin = "Anonymous";
      loader.load("/three-assets/img/galaxyTexture.jpg", function (texture) {
        document.body.classList.remove("loading");
        window.tunnel = new window.Tunnel(texture);
        setWindow1Loaded(true);

      });
    };
  }, [goIn]);
  useEffect(() => {
    axios.get(`/global`).then(function (response) {
      setFixedTitles(response.data);
    });
    let newStars = [];
    for (let i = 0; i < 500; i++) {
      newStars.push({
        style: {
          position: "absolute",
          top: Math.random() * 100 + "%",
          left: Math.random() * 100 + "%",
          opacity: 0.5 + Math.random() * 0.5,
          transform: `translateY(-50%) scale(${Math.random() * 0.7 + 0.3})`,
        },
      });
    }
    setStars(newStars);

  }, []);
  useEffect(() => {
    if (p1 && p2 && p3 && p4 && isLoading) {
      setLoadingMessage("");
      setTimeout(() => {
        if (isLoading) {
          setGoIn(true);
          setIsLoading(false);
          setFadeOutLogo(true);
          setWindowLoaded(true);
          setTimeout(() => {
            setFadeOutHole(true);
            document.getElementById("scene").classList.add("hide");
            setScaleUpPlanets(true);
            setTimeout(() => {
              document.getElementById("scene").classList.add("remove");
              setHideHole(true);
            }, 1000);
          }, 9000);
        }
      }, 0);
    }
  }, [p1, p2, p3, p4, isLoading]);
  /* listeners*/
  const _handleClosePopup = () => {
    setIsSidePopupOpen(false);
    setMenuOpen(false);
  };
  return (
    <DataContext.Provider
      value={{
        fixedTitles: fixedTitles,
      }}
    >
      <canvas
        id="scene"
        ref={hole}
        className={
          "mycanvas " +
          (fadeOutHole ? " hide " : "") +
          (hideHole ? " d-none " : " ")
        }
      ></canvas>

      <div
        className={
          "bg-linear linear-default loaded "
        }
      />
      {isSidePopupOpen ? (
        <div className={`bg-linear show linear-${sidePopup}`} />
      ) : (
        ""
      )}

      {!hideLogo && <div className="noclick"></div>}
      <div className="stars" id="starscontainer">
        {stars.map(function (star, i) {
          return (
            <div
              key={i.toString()}
              className={(i % 4 == 0 ? " animatestars " : "") + " single-star "}
              {...star}
            ></div>
          );
        })}
      </div>
      {/* <div class="stars"></div> */}
      {!hideLogo && (
        <div
          className={"  logowrapperr " + (fadeOutLogo ? " hide " : " ")}
          onClick={() => {
            if (p1 && p2 && p3 && p4) {
              setGoIn(true);
              setIsLoading(false);
              setFadeOutLogo(true);
              setWindowLoaded(true);
              setTimeout(() => {
                setFadeOutHole(true);
                document.getElementById("scene").classList.add("hide");
                setTimeout(() => {
                  setScaleUpPlanets(true);
                  setHideHole(true);
                  document.getElementById("scene").classList.add("remove");
                }, 1000);
              }, 6000);
            }
          }}
        >
          <Logo />
        </div>
      )}
      {!hideLogo && (
        <p className={"loading-msg " + (fadeOutLogo ? " hide " : " ")}>
          {loadingMessage}
        </p>
      )}
      <Canvas id="main-canvas" className={`${hideHole ? "active" : ""}`}>
        <Scene
          scaleUpPlanets={scaleUpPlanets}
          setWeee={setWeee}
          p1={p1}
          p2={p2}
          p3={p3}
          p4={p4}
          navigatingWithArrows={navigatingWithArrows}
          setNavigatingWithArrows={setNavigatingWithArrows}
          goIn={hideHole}
          isLoading={isLoading}
          initialCameraReset={initialCameraReset}
          sidePopup={sidePopup}
          isSidePopupOpen={isSidePopupOpen}
          windowLoaded={windowLoaded}
          refreshAnimation={refreshAnimation}
          {...{
            setMenuOpen,
            setHideLogo,
            setFadeOutLogo,
            setSidePopup,
            setP1,
            setP2,
            setP3,
            setP4,
          }}
        />
      </Canvas>
      {/* Start Menu  */}
      <div
        className={
          (currentProject !== "" || currentTeam !== "" ? " stack-back " : "") +
          " menu-stack " +
          (isLoading ? " pointer-none " : " ")
        }
      >
        <Menu
          setWeee={setWeee}
          setSearch={setSearch}
          setIsSidePopupOpen={setIsSidePopupOpen}
          weee={weee}
          setNavigatingWithArrows={setNavigatingWithArrows}
          menuOpen={menuOpen}
          refreshWee={refreshWee}
          setRefreshAnimation={() => {
            setRefreshAnimation(!refreshAnimation);
          }}
          onClick={() => {
            setMenuOpen(!menuOpen);
            setSidePopup("");
          }}
          team={currentTeam}
          project={currentProject}
          {...{
            setActivePopupOnWee,
            sidePopup,
            setSidePopup,
            setMenuOpen,
            setInitialCameraReset,
            setHideHole,
            setFadeOutHole,
            setRefreshWee,
          }}
          initialCameraReset={initialCameraReset}
          sidePopup={sidePopup}
        />
      </div>
      {/* End Menu  */}

      <div className="pos-z mobile-z-position">
        {/* Start Single Team Popup  */}
        <div
          className={
            (currentProject !== "" ? " stack-front " : "") + " team-black "
          }
        >
        </div>
        {/* End SingleTeam Popup  */}

        {/* Start Single Project Popup  */}

        {/* End Single Project Popup  */}
        <SidePopups
          active={sidePopup}
          closePopup={_handleClosePopup}
          setSidePopup={setSidePopup}
          setIsSidePopupOpen={setIsSidePopupOpen}
          setCurrentProject={setCurrentProject}
          setCurrentTeam={setCurrentTeam}
          data={["wtf", "htf", "projects", "contact", "search"]}
          search={search}
          allTeams={allTeams}
          setAllTeams={setAllTeams}
          setSearch={setSearch}
          setMenuOpen={setMenuOpen}
        ></SidePopups>
      </div>
    </DataContext.Provider>
  );
}
export default App;
