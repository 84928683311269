import axios from "axios";
import React, { useContext, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import Loader from "react-loader-spinner";
import Icon from "../../assets/icons/ro.svg";
import DataContext from "../../contexts/DataContext";

const ContactUs = (props) => {
  const { fixedTitles } = useContext(DataContext);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [status, setStatus] = useState();
  const [isLoading, setIsLoading] = useState();
  const [message, setMessage] = useState();
  const _handleChange = (e) => {
    setFormData({
      ...formData, [e.target.name]: e.target.value
    })
    setMessage("");
  }
  const _handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios.post("contact-us", formData)
      .then(function (res) {
        setFormData({
          name: "",
          email: "",
          message: ""
        });
        setIsLoading(false);
        setMessage(res.data.success_message);

      });
  }
  console.log('message', message);
  return (
    <div className="col-12 fh-100 px-0">
      <div className="white-box"></div>
      <div className="box-container w-100  pos-z scrollview">
        <img src={fixedTitles.titles['reach-out'].formatted_image}
          className="pop-title" alt="" />
        <form className="  pb-70 contact-form " onSubmit={_handleSubmit}>
          <div className="">
            <a href={`mailto:${fixedTitles.titles['email-destination'].title}`} className="fw-bold">
              {fixedTitles.titles['email-destination'].title}
            </a>
          </div>
          <div className="">
            <p className="input-title fw-bold">{fixedTitles.titles['full-name'].title} </p>
            <input type="text" name="name" onChange={_handleChange} value={formData['name']} required />
          </div>
          <div className="">
            <p className="input-title fw-bold">{fixedTitles.titles['e-mail'].title} </p>
            <input type="email" name="email" onChange={_handleChange} value={formData['email']} required />
          </div>

          <div className="">
            <p className="input-title fw-bold">{fixedTitles.titles['your-message'].title} </p>
            <textarea required placeholder="Enter text here.." name="message" onChange={_handleChange} value={formData['message']}></textarea>
          </div>
          <div className="mt-3 submit-wrapper position-relative d--flex">
            <div className="color1"></div>
            <div className="color2"></div>
            {isLoading ?
              <div className='loader-container'>
                <Loader
                  type="Oval"
                  color="black"
                  id="loader"
                  visible={true}
                  height={25}
                  width={25}
                />
              </div> :
              <input
                type="submit"
                value={fixedTitles.titles['submit'].title}
                className="text-white pointer pos-z fw-bold"
              />
            }
          </div>
          <div className="mt-3 success">{message}</div>

        </form>
      </div>
    </div>
  );
};

export default ContactUs;
