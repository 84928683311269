import React from 'react';

const BurgerIcon = props => {
    return (
        <div className="burger-menu  icon-row pointer" onClick={props.onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="25" viewBox="0 0 32 25">
                <g id="Group_128" data-name="Group 128" transform="translate(-5841 -1198.656)">
                    <rect id="Rectangle_194" data-name="Rectangle 194" width="32" height="5" rx="2.5" transform="translate(5841 1198.656)" fill="#fff" />
                    <rect id="Rectangle_195" data-name="Rectangle 195" width="32" height="5" rx="2.5" transform="translate(5841 1208.656)" fill="#fff" />
                    <rect id="Rectangle_196" data-name="Rectangle 196" width="32" height="5" rx="2.5" transform="translate(5841 1218.656)" fill="#fff" />
                </g>
            </svg>
        </div>
    )
}


export default BurgerIcon;