import React from 'react';

const InvolvedArtist = props => {
    if (props && Object.keys(props).length)
        return (
            <>
                <div className="col-6 col-lg-12 col-md-4" onClick={props.onClick}>
                    <div className=" pt-35 text-center">
                        <div className="small-person-image mx-auto">
                            <div className="img-wrapper ">
                                <img src={props.formatted_image} alt="" />
                            </div>
                        </div>
                        <h3 className="fs-10 fw-bold mt-1 text-center text-black">{props.name} </h3>
                        <p className="fs-8 text-center text-black">{props.tags?.reduce((accumulator, currentValue, index) => {
                            if (index > 0)
                                accumulator = accumulator + ","
                            return accumulator + " " + currentValue.title;
                        }, "")}</p>
                    </div>
                </div>
            </>
        )
    return <div></div>
}

export default InvolvedArtist;