import React, { useContext } from 'react';
import DataContext from '../../../contexts/DataContext';

const IgIcon = props => {
    const { fixedTitles } = useContext(DataContext);
    return (
        <div className="insta-icon icons-row  pointer">
            <a href={props.link} target="_blank" className="d-block" rel="noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" width="32.579" height="32.588" viewBox="0 0 32.579 32.588">
                    <g id="Group_130" data-name="Group 130" transform="translate(-5871.329 -2150.569)">
                        <path id="Path_138" data-name="Path 138" d="M5899.137,2155.34a16.3,16.3,0,1,0,4.771,11.528A16.271,16.271,0,0,0,5899.137,2155.34Zm-1.9,16.4a4.748,4.748,0,0,1-4.743,4.742h-9.745a4.747,4.747,0,0,1-4.743-4.742v-9.745a4.747,4.747,0,0,1,4.743-4.743h9.745a4.748,4.748,0,0,1,4.743,4.743Z" fill="#fff" />
                        <path id="Path_139" data-name="Path 139" d="M5892.491,2159.089h-9.745a2.9,2.9,0,0,0-2.9,2.9v9.745a2.9,2.9,0,0,0,2.9,2.9h9.745a2.9,2.9,0,0,0,2.9-2.9v-9.745A2.905,2.905,0,0,0,5892.491,2159.089Zm-4.878,14.1a6.335,6.335,0,1,1,6.334-6.335A6.342,6.342,0,0,1,5887.613,2173.193Zm5.848-10.808a1.334,1.334,0,1,1,1.334-1.334A1.336,1.336,0,0,1,5893.461,2162.385Z" fill="#fff" />
                        <path id="Path_140" data-name="Path 140" d="M5887.613,2162.508a4.35,4.35,0,1,0,4.35,4.35A4.349,4.349,0,0,0,5887.613,2162.508Z" fill="#fff" />
                    </g>
                </svg>
            </a>
        </div>
    )
}
export default IgIcon;