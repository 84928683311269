
import React from 'react';

const MailIcon = props => {
    return (
        <div className="mail-icon icons-row ">
            <a href={props.link}>
                <svg xmlns="http://www.w3.org/2000/svg" width="31.947" height="20.73" viewBox="0 0 31.947 20.73">
                    <g id="Group_161" data-name="Group 161" transform="translate(-5868.053 -3128.263)">
                        <g id="Group_107" data-name="Group 107">
                            <path id="Path_115" data-name="Path 115" d="M5868.053,3132.01v14.632l12.408-7.317Z" />
                            <path id="Path_116" data-name="Path 116" d="M5868.819,3148.781a1.487,1.487,0,0,0,.771.212h28.874a1.529,1.529,0,0,0,.957-.341l-13.62-8.029-1.571.926-1.572-.927Z" />
                            <path id="Path_117" data-name="Path 117" d="M5900,3132.251l-12,7.074,12,7.079Z" />
                            <path id="Path_118" data-name="Path 118" d="M5899.994,3129.662a1.538,1.538,0,0,0-1.53-1.4H5869.59a1.538,1.538,0,0,0-1.5,1.18l16.136,9.512Z" />
                        </g>
                    </g>
                </svg>
            </a>
        </div>
    )
}
export default MailIcon;