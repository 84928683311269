import React, { useContext, useEffect, useState } from "react";
import InvolvedArtist from "./InvolvedArtist";
import ArrowLeft from "./icons/arrow-left";
import ArrowRight from "./icons/arrow-right";
import DataContext from "../contexts/DataContext";
import BlackPopup from "./BlackPopup";
import ArtistInfo from "./ArtistInfo";
const ProjectInformation = (props) => {
  const { fixedTitles } = useContext(DataContext);
  const [activeArtistIndex, setActiveArtistIndex] = useState();
  const [involvedArtists, setInvolvedArtists] = useState([]);
  useEffect(() => {
    if (props.project)
      if (props.project.artist)
        setInvolvedArtists([props.project.artist, ...props.project.involved_artists.filter(el => el.id != props.project?.artist_id)]);
      else
        setInvolvedArtists(props.project.involved_artists)
  }, [props.project])
  return (
    <>
      <BlackPopup
        togglePopup={() => {
          if (props.setClosable)
            props.setClosable(true)
          setActiveArtistIndex(-1);
        }}
        closable={true}
        active={activeArtistIndex !== undefined && activeArtistIndex !== null && activeArtistIndex !== -1}
      >
        <ArtistInfo
          activeArtistIndex={activeArtistIndex}
          allArtists={involvedArtists}
          setActiveArtistIndex={setActiveArtistIndex}
          page={1}
          lastPage={1}
        />
      </BlackPopup>
      <div className="container-fluid pos-z h-100 px-0 px-md-0">
        <div
          className="box-container m-0 h-100 project-container mx-height-none"
          id="containerDiv"
        >
          <div className="h-100">
            <div className="pt-5  px-3">
              <div className="d-lg-flex">
                <div className="project-image">
                  <div className="img-wrapper">
                    <img src={props.project?.formatted_image} className=" " alt="" />
                  </div>
                </div>
                <div className="ml-lg-4 mt-lg-0 my-3">
                  <h1 className="fs-44 text-white lh-1p2 fw-black { ">
                    {props.project?.title}
                  </h1>
                  <p className="fs-14 text-white mt-3 lh-1p2 fw-book">
                    {props.project?.subtitle}
                  </p>
                </div>
              </div>
            </div>
            <div className="custom-row-columns d-lg-flex w-100">
              <div className="custom-row-columns-big px-3">
                <div className=" bg-white project-content p-md-5 p-4 mt-4">
                  <div
                    className="scrollview"
                    dangerouslySetInnerHTML={{ __html: props.project?.content }}
                  ></div>
                </div>
              </div>
              <div className=" custom-row-columns-small  px-3 pb-5">
                <div className="ml-lg-auto mx-auto mr-lg-0 mt-4">
                  <div>
                    <div className="ml-lg-auto mx-auto mr-lg-0 involved-box-top fs-12 fw-medium  text-black d-flex align-items-center justify-content-center">
                      {fixedTitles.titles['people-involved'].title}
                    </div>
                  </div>
                  <div>
                    <div className="ml-lg-auto mx-auto mr-lg-0 involved-box-bottom pl-4 pr-2 pb-4 ">
                      <div className="scrollview pr-3 mt-3">
                        <div className="row">
                          {
                            involvedArtists.map((el, index) => <InvolvedArtist key={el.id} onClick={() => {
                              setActiveArtistIndex(index);
                              props.setClosable(false);
                            }} {...el} />)
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  {props.pagination == false ? "" :
                    <div className="ml-lg-auto mx-auto mr-lg-0 d-flex  justify-content-center arrow-nav pt-2 ">
                      <div className="mr-3">
                        <ArrowLeft
                          inactive={props.projectIndex == 0}
                          onClick={() => {
                            if (props.projectIndex > 0) {
                              props.setCurrentProjectIndex(props.projectIndex - 1);
                              var divElement =
                                document.getElementById("containerDiv");
                              divElement.scroll({
                                top: 0,
                                behavior: "smooth",
                              });
                            }
                          }}
                        />
                      </div>
                      <div className="ml-3">
                        <ArrowRight
                          inactive={props.projectIndex == props.allProjects.length - 1 && props.page == props.lastPage}
                          onClick={() => {
                            if (props.projectIndex < props.allProjects.length - 1) {
                              props.setCurrentProjectIndex(props.projectIndex + 1);
                            }
                            else {
                              if (props.page != props.lastPage) {
                                props.loadMore(props.page + 1, () => props.setCurrentProjectIndex(props.projectIndex + 1));
                              }
                              else {
                                props.setCurrentProjectIndex(0);
                              }
                            }
                            var divElement =
                              document.getElementById("containerDiv");
                            divElement.scroll({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                        />
                      </div>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProjectInformation;
