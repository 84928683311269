import React, { useContext } from "react";
import DataContext from "../contexts/DataContext";
const Artist = (props) => {
  const { fixedTitles } = useContext(DataContext)
  return (
    <div className="col-6 who-single-item py-5 h-100">
      <div className="row align-items-center">
        <div className="col-xl-5">
          <div className="big-person-image mx-auto mr-xl-0">
            <div className="img-wrapper ">
              <img
                src={props.formatted_image}
                alt=""
                className="cursor-pointer"
                onClick={() => {
                  props.toggleClick();
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-7 text-xl-left text-center mt-xl-0 mt-4 d-flex align-items-between flex-column">
          <h3 className=" mb-7 fw-medium">{props.name}</h3>
          <p className="fs-14 fw-book ">
            {props.tags.reduce((accumulator, currentValue, index) => {
              if (index > 0)
                accumulator = accumulator + ","
              return accumulator + " " + currentValue.title;
            }, "")}
          </p>
          <div className="person-btn-wrapper text-center text-lg-left d-flex mt-10 justify-content-xl-start justify-content-center ">
            <div
              onClick={() => {
                props.toggleClick();
              }}
              className={
                "btn-whodf  fw-medium text-white d-inline-block pointer  position-relative d-flex " +
                (props.type === "blue" ? " blue " : " red ")
              }
            >
              <div className="color1"></div>
              <div className="color2"></div>
              <div className="pos-z text-center btn-whodf-text fw-medium">
                {fixedTitles.titles['view-profile'].title}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Artist;
