import React, { useEffect, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

const GLTFModal = ({
  path,
  position,
  rotation,
  rotationSpeed,
  isLoading,
  scaleUpPlanets,
  translate,
  planetType,
  scale,
  maxScale,
  aniamtePosition,
  goLeft,
  isSidePopupOpen,
  animatePlanet,
  sat,
  navigatingWithArrows,
  sidePopup,
  goIn,
  ...rest
}) => {
  const gltf = useGLTF(path, true);
  const mesh = React.useRef();

  const [scaleUpPlanets1, setScaleUpPlanets] = useState(scaleUpPlanets);
  const [animatePlanets, setAnimatePlanet] = useState(animatePlanet);
  const [hidePlanet, setHidePlanet] = useState(goLeft);
  const [isLoading1, setIsLoading] = useState(isLoading);
  const [distance, setDistance] = useState();
  useEffect(() => {
    setIsLoading(isLoading);
    if (animatePlanet == true) setAnimatePlanet(true);
    else setAnimatePlanet(false);
    setHidePlanet(goLeft);
    setScaleUpPlanets(scaleUpPlanets);
  }, [animatePlanet, goLeft, scaleUpPlanets, isLoading]);

  useFrame(() =>
    rotationSpeed
      ? ((mesh.current.rotation.x += rotationSpeed[0]),
        (mesh.current.rotation.y += rotationSpeed[1]),
        (mesh.current.rotation.z += rotationSpeed[2]))
      : null
  );

  useEffect(() => {
    mesh.current.scale.x = 0;
    mesh.current.scale.y = 0;
    mesh.current.scale.z = 0;
    setDistance(
      new THREE.Vector3(...position).distanceTo(
        new THREE.Vector3(...aniamtePosition)
      )
    );
  }, []);

  useEffect(() => {
    if (scaleUpPlanets1) {
      setTimeout(() => {
        var time = 2000;
        var maxNumber = 1;
        var step = 0.1;
        var numberOfSteps = maxNumber / step;
        var intervalTime = time / numberOfSteps / 10000;
        var interval = setInterval(function () {
          if (mesh.current.scale.x <= maxScale - intervalTime) {
          } else {
            setScaleUpPlanets(false);
            clearInterval(interval);
          }
        }, intervalTime);
      }, 500);
    } else {
    }
  }, [isLoading1, scaleUpPlanets1]);

  const animate = (start, end, flag) => {
    togglePlanets();
    let newXPosition = mesh.current.position.x;
    let newYPosition = mesh.current.position.y;
    let newZPosition = mesh.current.position.z;
    let coef = navigatingWithArrows ? (1 / distance) * 150 : 30;
    // console.log("coef", coef, distance);
    if (isLoading1) coef = 20;
    if (start[0] < end[0] && mesh.current.position.x < end[0]) {
      let step = Math.abs(start[0] - end[0]) / coef;
      newXPosition = mesh.current.position.x + step;
    } else if (start[0] > end[0] && mesh.current.position.x > end[0]) {
      let step = Math.abs(start[0] - end[0]) / coef;
      newXPosition = mesh.current.position.x - step;
    }
    if (start[1] < end[1] && mesh.current.position.y < end[1]) {
      let step = Math.abs(start[1] - end[1]) / coef;
      newYPosition = mesh.current.position.y + step;
    } else if (start[1] > end[1] && mesh.current.position.y > end[1]) {
      let step = Math.abs(start[1] - end[1]) / coef;
      newYPosition = mesh.current.position.y - step;
    }
    if (start[2] < end[2] && mesh.current.position.z < end[2]) {
      let step = Math.abs(start[2] - end[2]) / coef;
      newZPosition = mesh.current.position.z + step;
    } else if (start[2] > end[2] && mesh.current.position.z > end[2]) {
      let step = Math.abs(start[2] - end[2]) / coef;
      newZPosition = mesh.current.position.z - step;
    }
    mesh.current.position.set(newXPosition, newYPosition, newZPosition);
  };
  const togglePlanets = () => {
    var time = 4000;
    var maxNumber = 1;
    var step = 0.1;
    var numberOfSteps = maxNumber / step;
    var intervalTime = time / numberOfSteps / 1000;
    let minScale = 0;
    if (hidePlanet && !isLoading1 && sidePopup && planetType != sidePopup) {
      if (mesh.current.scale.x >= minScale) {
        let newX = mesh.current.scale.x - intervalTime;
        mesh.current.scale.x = newX >= 0 ? newX : 0;
        let newY = mesh.current.scale.y - intervalTime;
        mesh.current.scale.y = newY >= 0 ? newY : 0;
        let newZ = mesh.current.scale.z - intervalTime;
        mesh.current.scale.z = newZ >= 0 ? newZ : 0;
      }
    } else if (
      (!animatePlanets && !isLoading1 && planetType != sidePopup) ||
      !isSidePopupOpen
    ) {
      if (mesh.current.scale.x <= maxScale - intervalTime) {
        mesh.current.scale.x += intervalTime;
        mesh.current.scale.y += intervalTime;
        mesh.current.scale.z += intervalTime;
      } else if (mesh.current.scale.x > maxScale) {
        mesh.current.scale.x -= intervalTime;
        mesh.current.scale.y -= intervalTime;
        mesh.current.scale.z -= intervalTime;
      }
    } else if (planetType == sidePopup && isSidePopupOpen) {
      let tempMaxScale = maxScale;

      if (planetType == sidePopup && isSidePopupOpen) {
        //increase the size of active planet
        tempMaxScale += 10;
        if (mesh.current.scale.x <= tempMaxScale - intervalTime) {
          mesh.current.scale.x += intervalTime;
          mesh.current.scale.y += intervalTime;
          mesh.current.scale.z += intervalTime;
        } else if (mesh.current.scale.x > tempMaxScale) {
          mesh.current.scale.x -= intervalTime;
          mesh.current.scale.y -= intervalTime;
          mesh.current.scale.z -= intervalTime;
        }
      }
    }
  };

  useFrame(() => {
    if (goIn || !isSidePopupOpen) {
      if (goLeft || !isSidePopupOpen)
        animate(aniamtePosition, position, "close");
      else if (animatePlanet) {
        animate(position, aniamtePosition, "opening");
      }
    }
  });

  return (
    <mesh
      ref={mesh}
      castShadow
      {...rest}
      position={position}
      rotation={rotation}
    >
      <primitive object={gltf.scene} dispose={null} />
    </mesh>
  );
};

export default GLTFModal;
