const SearchResult = (props) => {
    return <div className='search-result w-100' onClick={props.onClick}>
        <div className="pl-4 content-container position-relative">
            <div className="main-image" style={{ backgroundImage: `url(${props.image})` }} />
            <div className="name fw-medium">
                {props.title}
            </div>
            <div className="description">{props.description}</div>
            <div className="icon position-absolute"><img src={props.icon} /></div>
        </div>
    </div>
}
export default SearchResult;