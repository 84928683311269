
import React from 'react';

const SearchIcon = props => {
    return (
        <div className="search-icon icon-row pointer" onClick={props.onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="31.979" height="32.131" viewBox="0 0 31.979 32.131">
                <path id="Path_135" data-name="Path 135" d="M5815.039,1222l-7.052-7.052a13.04,13.04,0,1,0-4.364,4.289l7.09,7.09a3.059,3.059,0,0,0,4.326-4.327Zm-18.083-6.908a7,7,0,1,1,7-7A7,7,0,0,1,5796.956,1215.091Z" transform="translate(-5783.956 -1195.091)" fill="#fff" />
            </svg>
        </div>
    )
}
export default SearchIcon;