import React, { useContext, useState, useEffect } from "react";
import FbIcon from "./icons/socials/fb";
import IgIcon from "./icons/socials/ig";
import TwIcon from "./icons/socials/tw";
import ArrowLeft from "./icons/arrow-left";
import ArrowRight from "./icons/arrow-right";
import axios from "axios";
import BlackPopup from "./BlackPopup";
import ProjectInformation from "./ProjectInformation";
import DataContext from "../contexts/DataContext";
import ProjectGrid from "./ProjectsGrid";

const Artist = (props) => {
  const [artist, setArtist] = useState({});
  const [currentProjectIndex, setCurrentProjectIndex] = useState();
  const { fixedTitles } = useContext(DataContext);
  useEffect(() => {
    if (props.activeArtistIndex !== null && props.activeArtistIndex !== undefined)
      setArtist(props.allArtists[props.activeArtistIndex]);
  }, [props.activeArtistIndex]);
  return (
    <>
      <div
        className={
          (currentProjectIndex !== undefined && currentProjectIndex != null ? " stack-front " : "") + " project-black "
        }
      >
        {/* <BlackPopup
          togglePopup={() => {
            setCurrentProjectIndex();
          }}
          active={currentProjectIndex !== undefined && currentProjectIndex != null}
        >
          {artist.projects && artist.projects.length ?
            <ProjectInformation
              project={artist.projects[currentProjectIndex]}
              projectIndex={currentProjectIndex}
              setCurrentProjectIndex={setCurrentProjectIndex}
              allProjects={artist.projects}
              page={1}
              lastPage={1}
            /> : ""
          }
        </BlackPopup> */}
      </div>
      <div className="container-fluid pos-z fh-100 d-flex align-items-center px-0 px-md-0">
        <div className="box-container w-100 ">
          <div className="row">
            <div className="col-xl-2">
              <div className="single-team mx-auto">
                <div className="img-wrapper">
                  <img src={artist?.formatted_image} className="rounded-circle " alt="" />
                </div>
              </div>{props.pagination == false ? "" :
                <div className="d-flex mt-5 justify-content-center">
                  <div className="mr-3">
                    <ArrowLeft
                      inactive={props.activeArtistIndex == 0}
                      onClick={() => {
                        if (props.activeArtistIndex > 0) props.setActiveArtistIndex(props.activeArtistIndex - 1);
                      }}
                    />
                  </div>
                  <div className="ml-3">
                    <ArrowRight
                      inactive={props.activeArtistIndex == props.allArtists.length - 1 && props.page == props.lastPage}
                      onClick={() => {
                        if (props.activeArtistIndex < props.allArtists.length - 1) {
                          props.setActiveArtistIndex(props.activeArtistIndex + 1);
                        }
                        else {
                          if (props.page != props.lastPage) {
                            props.loadMore(props.page + 1, () => props.setActiveArtistIndex(props.activeArtistIndex + 1));
                          }
                          else {
                            props.setActiveArtistIndex(0);

                          }
                        }
                      }}
                    />
                  </div>
                </div>}
            </div>
            <div className="col-xl-10 mt-xl-0 mt-4">
              <div className="row ">
                <div className="col-lg-12">
                  <div className="ml-lg-4">
                    <h1 className="fs-44 text-white lh-1p2 fw-black {">
                      {artist.name}
                    </h1>
                    <p className="text-blue fs-14 fw-bold lh-1p2 mt-lg-0 mt-3 mb-20 team-text">
                      {artist?.tags?.reduce((accumulator, currentValue, index) => {
                        if (index > 0)
                          accumulator = accumulator + ","
                        return accumulator + " " + currentValue.title;
                      }, "")}                    </p>
                    <p className="fs-14 text-white lh-1p2 w-85">{artist.text}</p>
                    {artist && artist.personal ? artist.personal.split(',').map(el =>
                      <a
                        key={Math.random()}
                        href={el}
                        target="_blank"
                        className="btn-whodf bg-blue d-inline-block text-white fw-bold mt-14 mr-3 transition hover-bg-white-blue px-2"
                      >
                        {el.replace("https://", "").replace("http://", "")}
                      </a>) : ""}
                    <div className="user-socials mt-14 lh-1 ">
                      {artist.facebook && (
                        <FbIcon link={artist.facebook} />
                      )}
                      {artist.instagram && (
                        <IgIcon link={artist.instagram} />
                      )}
                      {artist.twitter && <TwIcon link={artist.twitter} />}
                    </div>
                  </div>
                </div>
                {artist.projects_count ?
                  <div className="col-lg-12 mb-5 mb-md-0  pt-40">
                    <div className="border-radius-22  bg-white px-md-5 pb-md-5  p-4 ml-lg-4">
                      <h2 className="fw-black pt-1 pb-4 text-black">
                        {fixedTitles.titles['selected-works'].title}
                      </h2>
                      <div className="row">
                        {artist.id ?
                          <ProjectGrid className="pb-0" colClassName="col-lg-3" loadMore={false} setClosable={props.setClosable} artist={artist} /> : ""}
                        {/* {artist.projects?.map(function (proj, i) {
                        return (
                          <div
                            className="col-lg-3 col-md-6 mt-3"
                            key={i.toString()}
                            onClick={() => {
                              setCurrentProjectIndex(i);
                            }}
                          >
                            <div className="img-wrapper  border-radius-22 overflow-hidden pointer">
                              <img
                                src={proj.formatted_image}
                                className="scale-hover"
                                alt=""
                              />
                            </div>
                          </div>
                        );
                      })} */}
                      </div>
                    </div>
                  </div> : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Artist;
