import React, { useState, useEffect, useRef, useContext } from "react";
import FbIcon from "./icons/socials/fb";
import IgIcon from "./icons/socials/ig";
import SearchIcon from "./icons/search";
import TwIcon from "./icons/socials/tw";
import BurgerIcon from "./icons/burger";
import CmtIcon from "./icons/cmt";
import ArrowLeft from "../assets/icons/arrow-left.svg";
import DmIcon from "../assets/icons/dmIcon.svg";
import Planets from "./icons/planets";
import { MainContext } from "../Context";
import ArrowRight from "../assets/icons/arrow-right.svg";
import CircleIcon from "./icons/circle";
import MailIcon from "./icons/mail";
import DataContext from "../contexts/DataContext";
import axios from "axios";
const Menu = (props) => {
  const messagesEndRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(props.menuOpen);
  const [smallPopup, setSmallPopup] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [side, setSide] = useState(props.sidePopup);
  const [searchText, setSearchText] = useState("");

  const [initialCameraReset, setInitialCameraReset] = useState(
    props.initialCameraReset
  );
  const { fixedTitles } = useContext(DataContext);
  useEffect(() => {
    setMenuOpen(props.menuOpen);
    if (!props.menuOpen) setSmallPopup("");
    setSide(props.sidePopup);

    setInitialCameraReset(props.initialCameraReset);
  }, [props.menuOpen, props.sidePopup, props.initialCameraReset]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (props.team !== "" || props.project !== "") {
      setSmallPopup("");
    }
  }, [props.team, props.project]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const submitForm = (e) => {
    e.preventDefault();
    if (message.length > 0) {
      let formData = new FormData();
      formData.append('message', message);
      axios.post("/note", formData)
      setMessage("");
      setMessages(messages.concat(message));
    }
  };

  const changePlanet = (position) => {
    props.setSidePopup("");
    props.setInitialCameraReset(!initialCameraReset);
    props.setMenuOpen(true);
    props.setNavigatingWithArrows(true);
    const planetsOrder = ["htf", "wtf", "contact", "projects"];
    let currentIndex = planetsOrder.indexOf(side);

    if (
      currentIndex == -1 ||
      (position == "right" && currentIndex == planetsOrder.length - 1)
    ) {
      props.setSidePopup(planetsOrder[0]);
    } else if (position == "left" && currentIndex == 0) {
      props.setSidePopup(planetsOrder[planetsOrder.length - 1]);
    } else if (position == "right") {
      props.setSidePopup(planetsOrder[currentIndex + 1]);
    } else if (position == "left") {
      props.setSidePopup(planetsOrder[currentIndex - 1]);
    }
  };

  const submitSearch = (e) => {
    e.preventDefault();
    setSmallPopup("");
    setSearchText("");
    props.setSidePopup("search");
    props.setSearch(searchText)
  };

  return (
    <div className={(menuOpen ? "open " : "") + "menu-right "}>
      <div className="white-menu d-flex align-items-center justify-content-center">
        <div className="menu-arrow">
          <div className="d-inline-block">
            <img
              src={ArrowLeft}
              className=" mr-2 pointer"
              alt=""
              onClick={() => {
                changePlanet("left");
              }}
            />
            <img
              src={ArrowRight}
              className=" ml-2 pointer"
              alt=""
              onClick={() => {
                changePlanet("right");
              }}
            />
          </div>
        </div>
      </div>
      <div className="menu-top-icons d-flex   py-4">
        <div className="floating-menu-top d-flex flex-row">
          {/* wee Icon  */}
          <div className="position-relative">
            <CircleIcon
              onClick={() => {
                const activePopup = props.sidePopup
                props.setSidePopup("");
                props.setWeee(true);
                props.setRefreshWee(!props.refreshWee);
                props.setMenuOpen(false);
                props.setHideHole(false);
                document.getElementById("scene").classList.remove("remove");
                setSmallPopup("");
                setTimeout(() => {
                  props.setFadeOutHole(false);
                  document.getElementById("scene").classList.remove("hide");
                  setTimeout(() => {
                    props.setFadeOutHole(true);
                    document.getElementById("scene").classList.add("hide");
                    setTimeout(() => {
                      props.setWeee(false);
                      props.setHideHole(true);
                      document.getElementById("scene").classList.add("remove");
                      setTimeout(() => {
                        props.setSidePopup(activePopup)
                      }, 1000)
                    }, 1000);
                  }, 3000);
                }, 500);
              }}
            />
            <h2 className={`weee ${props.weee ? " active" : ""}`}>
              {fixedTitles?.titles['wee']?.title}

            </h2>
          </div>

          {/* Start Comment  */}
          <div className="position-relative">
            <CmtIcon
              onClick={() => {
                smallPopup !== "quicknote"
                  ? setSmallPopup("quicknote")
                  : setSmallPopup("");
              }}
            />
            {/*Start  comment box  */}
            <div
              className={
                "contact-message-box " +
                (smallPopup === "quicknote" ? " show " : " ")
              }
            >
              <span className="arrow"></span>
              <div className="upper">
                <p className="fs-14 fw-black text-left text-black-1a">
                  {fixedTitles?.titles['drop-us-a-quick-note']?.title}
                </p>
                <p className="fs-10 fw-medium text-left text-black-1a">
                  {fixedTitles?.titles['or-write-us']?.title}
                </p>
              </div>
              <div className="lower">
                <form onSubmit={submitForm}>
                  <input
                    type="text"
                    placeholder={fixedTitles?.titles['quick-note-type-message-here']?.title}
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                  />
                  <img src={DmIcon} alt="" />
                  <input type="submit" />
                </form>
                <div className="messages">
                  {messages.map(function (msg, i) {
                    return (
                      <div
                        className="single-message ml-auto"
                        key={i.toString()}
                      >
                        <p className="fs-11 lh-1p2 text-left fw-medium text-black-1a">
                          {msg}
                        </p>
                      </div>
                    );
                  })}
                  <div ref={messagesEndRef} />
                </div>
              </div>
            </div>
            {/*End comment box  */}
          </div>
          {/* End Comment  */}

          {/* Start Search  */}
          <div className="position-relative">
            <SearchIcon
              onClick={() => {
                if (smallPopup !== "search") {
                  setSmallPopup("search");
                  setSearchText("");
                } else {
                  setSmallPopup("");
                }
              }}
            />
            <div
              className={
                "search-box " + (smallPopup === "search" ? " show " : " ")
              }
            >
              <span className="arrow"></span>
              <form onSubmit={submitSearch}>
                <SearchIcon onClick={submitSearch} />
                <input
                  placeholder={fixedTitles?.titles['menu-search-placeholder']?.title}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </form>
            </div>
          </div>
          {/* End Search  */}

          <div className="position-relative">
            <Planets
              onClick={() => {
                smallPopup !== "menu"
                  ? setSmallPopup("menu")
                  : setSmallPopup("");
              }}
            />
            <div
              className={
                "burger-sidebar" + (smallPopup == "menu" ? " show " : "")
              }
            >
              <div
                className={"fw-bold" + (side === "wtf" ? " active " : " ")}
                onClick={() => {
                  props.setSidePopup("wtf");
                  props.setInitialCameraReset(!initialCameraReset);
                  props.setMenuOpen(true);
                }}
              >
                {fixedTitles?.titles['menu-what-the-f']?.title} <span className="arrow"></span>
              </div>
              <div
                className={"fw-bold" + (side === "htf" ? " active " : " ")}
                onClick={() => {
                  props.setSidePopup("htf");
                  props.setInitialCameraReset(!initialCameraReset);
                  props.setMenuOpen(true);
                }}
              >
                {fixedTitles?.titles['menu-who-the-f']?.title} <span className="arrow"></span>
              </div>
              <div
                className={"fw-bold" + (side === "projects" ? " active " : " ")}
                onClick={() => {
                  props.setSidePopup("projects");
                  props.setInitialCameraReset(!initialCameraReset);
                  props.setMenuOpen(true);
                }}
              >
                {fixedTitles?.titles['menu-explore-projects']?.title} <span className="arrow"></span>
              </div>
              <div
                className={"fw-bold" + (side === "contact" ? " active " : " ")}
                onClick={() => {
                  props.setSidePopup("contact");
                  props.setInitialCameraReset(!initialCameraReset);
                  props.setMenuOpen(true);
                }}
              >
                {" "}
                {fixedTitles?.titles['menu-reach-out']?.title} <span className="arrow"></span>
              </div>
            </div>
          </div>
        </div>
        {/* Start Burger Icon  */}
        <div
          className="position-relative burger-menu-icon"
          onMouseEnter={() => setSmallPopup("")}
          onMouseLeave={() => setSmallPopup("")}
        >
          <BurgerIcon
            onClick={() => {
              props.onClick();
            }}
          />
        </div>
        {/* End Buger Icon  */}
      </div>
      <div className="social-icons py-4">
        <MailIcon link={"mailto:hello@wormhole.com"} />
        <FbIcon link={fixedTitles?.titles['facebook-link']?.title} />
        <IgIcon link={fixedTitles?.titles['instagram-link']?.title} />
        <TwIcon link={fixedTitles?.titles['twitter-link']?.title} />
      </div>
    </div>
  );
};

export default Menu;
