import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "../icons/search";
import DataContext from "../../contexts/DataContext";
import axios from "axios";
import SearchResult from "../Search/SearchResult";
import Loader from "react-loader-spinner";
import ArtistInfo from "../ArtistInfo";
import BlackPopup from "../BlackPopup";
import ProjectInformation from "../ProjectInformation";
const Search = (props) => {
  const [results, setResults] = useState();
  const [search, setSearch] = useState(props.search);
  const [displayedSearch, setDisplayedSearch] = useState(props.search);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [loadMoreLoader, setLoadMoreLoader] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [activeArtistIndex, setActiveArtistIndex] = useState(-1);
  const [activeProjectIndex, setActiveProjectIndex] = useState(-1);
  const [initialLoader, setInitialLoader] = useState(true);
  const { fixedTitles } = useContext(DataContext)
  const fetchData = (page) => {
    if (page !== 1) {
      setLoadMoreLoader(true)
    }
    axios.get(`global/search/${search}?page=${page}`).then(function (response) {
      if (page != 1)
        setResults([...results, ...response.data.results.data]);
      else {
        setResults(response.data.results.data);
      }
      setLastPage(response.data.results.last_page);
      setPage(page);
      setLoadMoreLoader(false);
      setSearchLoader(false);
      setDisplayedSearch(search)
      setInitialLoader(false);
    });
  }
  useEffect(() => {
    fetchData(1);
  }, []);
  const _openArtist = (el) => {
    setActiveArtistIndex(results.findIndex(el2 => el2.name && el.id == el2.id))
  }
  const _openProject = (el) => {
    setActiveProjectIndex(results.findIndex(el2 => el2.title && el.id == el2.id))
  }
  const _handleSearch = (e) => {
    e.preventDefault();
    fetchData(1);
  }
  const _handleChange = (e) => {
    setSearch(e.target.value);
  }
  return (
    <>

      <div className="col-lg-9 px-0  fh-100  search-results">
        <div className="white-box"></div>
        <div className="box-container-0 w-100  pos-z">
          <img src={fixedTitles.titles['global-search-icon'].formatted_image} className="pop-title" alt="" />

          <div className="row mt-4 align-items-center justify-content-between">
            <div className="mt-md-0 col-md-auto col-12 text-center d-flex justify-content-center mb-3 mb-md-0">
              <div className="search-hdf search-search">
                <form className="d-flex align-items-center"
                  onSubmit={_handleSearch}>
                  <span onClick={_handleSearch}><SearchIcon /></span>
                  <input
                    type="text"
                    className="ml-2"
                    placeholder={fixedTitles.titles['global-search-enter-search-text-here'].title}
                    onChange={_handleChange}
                    value={search}
                  />
                </form>
              </div>
            </div>
            <div className="col-md-auto col-12 text-center">
              <p className="">{fixedTitles.titles['showing-results-for'].title} {displayedSearch}</p>
            </div>
          </div>

          <div className=" py-70 mt-3  scrollview">
            <div className="row justify-content-center justify-content-md-start">
              {results && results.length && !initialLoader ?
                results.map(el =>
                  <div className="col-auto col-md-6 col-xl-3 mb-2">
                    <SearchResult onClick={() => el.name ? _openArtist(el) : _openProject(el)} icon={el.name ? '/images/artist-search-icon.svg' : '/images/project-search-icon.svg'} title={el.title || el.name} image={el.formatted_image} description={el.subtitle || el.text} />
                  </div>
                )
                : initialLoader ?
                  <div className="text-center w-100">
                    <Loader
                      type="Oval"
                      color="#00BFFF"
                      visible={true}
                      height={50}
                      width={50}
                    />
                  </div>
                  : ""}
              {lastPage != page ?
                <div className="col-12 d-flex justify-content-center load-more mt-3">
                  <button onClick={() => fetchData(page + 1)} className="btn btn-outline-dark pl-5 pr-5 d-flex align-items-center">
                    <Loader
                      type="Oval"
                      color="#00BFFF"
                      visible={loadMoreLoader}
                      height={25}
                      width={25}
                    />
                    {fixedTitles.titles['artist-load-more'].title}</button>
                </div> : ""}
            </div>
          </div>
        </div>
      </div>
      {(activeArtistIndex !== undefined && activeArtistIndex !== null && activeArtistIndex !== -1)
        ?
        <BlackPopup
          togglePopup={() => {
            setActiveArtistIndex(-1);
          }}
          active={true}
        >
          <ArtistInfo
            activeArtistIndex={activeArtistIndex}
            allArtists={results}
            pagination={false}
          />
        </BlackPopup> : ""
      }
      {(activeProjectIndex !== undefined && activeProjectIndex !== null && activeProjectIndex !== -1) ?
        <BlackPopup
          togglePopup={() => {
            setActiveProjectIndex(-1);
          }}
          active={true}
        >
          <ProjectInformation
            project={results[activeProjectIndex]}
            projectIndex={activeProjectIndex}
            allProjects={results}
            pagination={false}
          />
        </BlackPopup> : ""
      }
    </>
  );
};

export default Search;
