import React from 'react';

const ArrowLeft = props => {
    return (
        <div onClick={props.onClick} className={`pointer circular-click ${props.inactive ? " pe-none" : " "}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" className={`${props.inactive ? " half-opacity pe-none" : " "}`}>
                <g id="Group_161" data-name="Group 161" transform="translate(-1729.375 -1916.078)">
                    <circle id="Ellipse_14" data-name="Ellipse 14" cx="25" cy="25" r="25" transform="translate(1729.375 1916.078)" fill="#fff" />
                    <g id="Group_53" data-name="Group 53">
                        <rect id="Rectangle_74" data-name="Rectangle 74" width="6.118" height="20.649" rx="3.059" transform="translate(1742.911 1941.078) rotate(-45)" />
                        <rect id="Rectangle_75" data-name="Rectangle 75" width="6.118" height="20.649" rx="3.059" transform="translate(1757.513 1926.477) rotate(45)" />
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default ArrowLeft;