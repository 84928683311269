import React from 'react';

const CmtIcon = props => {
    return (
        <div className="cmt-icon icon-row pointer " onClick={props.onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="29.454" height="30.653" viewBox="0 0 29.454 30.653">
                <path id="Path_134" data-name="Path 134" d="M5754.42,1220.843a14.84,14.84,0,1,0-4.107,2.951l3.311,2.483a1.014,1.014,0,0,0,1.606-.993Z" transform="translate(-5729.164 -1195.83)" fill="#fff" />
            </svg>
        </div>
    )
}
export default CmtIcon;