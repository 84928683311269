import React from 'react';

const ArrowRight = props => {
    return (
        <div onClick={props.onClick} className={`pointer circular-click ${props.inactive ? " pe-none" : " "}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" className={`${props.inactive ? " half-opacity pe-none" : " "} `}>
                <g id="Group_164" data-name="Group 164" transform="translate(-1822.375 -1916)">
                    <circle id="Ellipse_15" data-name="Ellipse 15" cx="25" cy="25" r="25" transform="translate(1822.375 1916)" fill="#fff" />
                    <g id="Group_163" data-name="Group 163">
                        <g id="Group_162" data-name="Group 162">
                            <g id="Group_55" data-name="Group 55">
                                <rect id="Rectangle_76" data-name="Rectangle 76" width="6.118" height="20.649" rx="3.059" transform="translate(1844.238 1955.601) rotate(-135)" />
                                <rect id="Rectangle_77" data-name="Rectangle 77" width="6.118" height="20.649" rx="3.059" transform="translate(1858.839 1941) rotate(135)" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default ArrowRight;