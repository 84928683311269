import React from 'react';
const Project = props => {
  return (
    <div className={`single-project-item position-relative custom-col mt-4px pointer 
    ${props.className ? props.className : ""}`} onClick={props.toggleClick}>
      <div className="img-wrapper ">
        <img src={props.project.formatted_image} alt="" />
        <div
          className="project-info">
          <div className="d-flex align-items-end mb-1 justify-content-between w-100">
            <div>
              <h4 className="text-white fw-bold lh-1p2 fw-black {">{props.project.title}</h4>
              <p className="fs-16 fw-medium  text-white lh-1p2">{props.project.subtitle}</p>
            </div>
            <svg className='flex-shrink-0' xmlns="http://www.w3.org/2000/svg" height="22.995" viewBox="0 0 14.905 22.995">
              <g id="Group_121" data-name="Group 121" transform="translate(-2410.048 -1766.718)">
                <g id="Group_120" data-name="Group 120">
                  <rect id="Rectangle_185" data-name="Rectangle 185" width="4.818" height="16.26" rx="2.409" transform="matrix(0.707, -0.707, 0.707, 0.707, 2410.048, 1770.125)" fill="#fff" />
                  <rect id="Rectangle_186" data-name="Rectangle 186" width="16.26" height="4.818" rx="2.409" transform="translate(2410.048 1786.307) rotate(-45)" fill="#fff" />
                </g>
              </g>
            </svg>
          </div>
        </div >
      </div>
    </div>
  )
}
export default Project;