import React from 'react';
const CloseBtn = props => {
    let color = "#fff";
    if (props.black) {
        color = "#000";
    }
    return (
        <div className={`close-btn pointer scale-hover ${props.className}`} onClick={() => {
            props.togglePopup()
        }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="27.394" height="27.393" viewBox="0 0 27.394 27.393">
                <path id="Path_61" data-name="Path 61" d="M69.609,1221.794l8.474-8.474a3.059,3.059,0,0,0-4.326-4.327l-8.475,8.475-8.474-8.475a3.059,3.059,0,1,0-4.326,4.327l8.474,8.474-8.474,8.474a3.059,3.059,0,1,0,4.326,4.326l8.474-8.474,8.475,8.474a3.059,3.059,0,0,0,4.326-4.326Z" transform="translate(-51.586 -1208.097)"
                    fill={color}
                />
            </svg>
        </div>
    )
}


export default CloseBtn;