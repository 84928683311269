import React, { Suspense, useState, useEffect, useRef } from "react";
import GLTFModal from "./GLTFModal";
import GLTFGROUP from "./GLTFGroup";
import { useThree, useFrame } from "@react-three/fiber";
import * as THREE from "three";
const Fallback = (props) => {
  useEffect(() => {
    return () => {
      props.setLoaded();
    };
  }, []);
  return null;
};
const Scene = (props) => {
  const {
    camera,
    set,
    gl: { domElement },
  } = useThree();
  const [scaleUpPlanets, setScaleUpPlanets] = useState(props.scaleUpPlanets);

  useEffect(() => {
    setScaleUpPlanets(props.scaleUpPlanets);
  }, [props.sidePopup, props.isLoading, props.scaleUpPlanets]);

  const [refreshX, setRefreshX] = useState(false);
  const [refreshY, setRefreshY] = useState(false);
  const [refreshZ, setRefreshZ] = useState(false);
  useEffect(() => {
    camera.position.z = -1;
    camera.position.x = 0;
    camera.position.y = 0;
    camera.fov=45;
    camera.updateProjectionMatrix();
    console.log('camera',camera.fov)

  }, []);

  const controls = useRef();
  useFrame((state) => {
    controls.current.update();
  });
  const initialCameraReset = () => {
    if (window.screen.width < 500) {
      return null;
    }
    let intervalX = setInterval(() => {
      if (camera.position.x < 0.5 && camera.position.x >= 0) {
        clearInterval(intervalX);
      }
      if (camera.position.x > 0.5) camera.position.x -= 0.1;
      else if (camera.position.x <= 0) camera.position.x += 0.1;
    }, 0.1);
    let intervalY = setInterval(() => {
      if (camera.position.y < 0.5 && camera.position.y >= 0) {
        clearInterval(intervalY);
      }
      if (camera.position.y > 0.5) camera.position.y -= 0.1;
      else if (camera.position.y <= 0.5) camera.position.y += 0.1;
    }, 0.1);
    let intervalZ = setInterval(() => {
      if (
        camera.position.z < 10 &&
        camera.position.z >= 9.5 &&
        window.innerWidth > 500
      ) {
        clearInterval(intervalZ);
      } else if (
        camera.position.z < 20 &&
        camera.position.z >= 19.5 &&
        window.innerWidth <= 500
      ) {
        clearInterval(intervalZ);
      }
      if (camera.position.z > 10) camera.position.z -= 0.1;
      else if (camera.position.z <= 10) camera.position.z += 0.1;
    }, 0.1);
  };

  const zoomIn = () => {
    let interval = setInterval(() => {
      if (camera.position.z > 200) camera.position.z -= 0.25;
      else if (camera.position.z > 150) camera.position.z -= 0.5;
      else if (camera.position.z > 100) camera.position.z -= 0.75;
      else if (camera.position.z > 50) camera.position.z -= 1;
      else camera.position.z -= 1;
      if (camera.position.z < 10 && window.innerWidth > 500) {
        props.setHideLogo(true);
        clearInterval(interval);
      } else if (camera.position.z < 20 && window.innerWidth <= 500) {
        clearInterval(interval);
        props.setHideLogo(true);
      }
    }, 0.1);
  };

  useEffect(() => {
    //weeeeee animation
    setRefreshX(false);
    setRefreshY(false);
    setRefreshZ(false);

    if (!props.isLoading) {
      if (camera.position.z == 250) {
        camera.position.z = 250;
        zoomIn();
      } else if (
        !refreshX &&
        !refreshY &&
        !refreshZ &&
        props.p1 &&
        props.p2 &&
        props.p3 &&
        props.p4
      ) {
        props.setHideLogo(false);

        let intervalX = setInterval(() => {
          if (camera.position.x <= 0.5 && camera.position.x >= 0) {
            clearInterval(intervalX);
            setRefreshX(true);
          }
          if (camera.position.x > 1) camera.position.x -= 0.5;
          else if (camera.position.x <= 0) camera.position.x += 0.5;
        }, 0.1);
        let intervalY = setInterval(() => {
          if (camera.position.y <= 0.5 && camera.position.y >= 0) {
            clearInterval(intervalY);
            setRefreshY(true);
          }
          if (camera.position.y > 0.5) camera.position.y -= 0.5;
          else if (camera.position.y <= 0.5) camera.position.y += 0.5;
        }, 0.1);
        let intervalZ = setInterval(() => {
          if (camera.position.z < 252 && camera.position.z >= 250) {
            clearInterval(intervalZ);
            setRefreshZ(true);
          }
          if (camera.position.z > 250) camera.position.z -= 1;
          else if (camera.position.z <= 250) camera.position.z += 1;
        }, 0.1);
      }
    }
  }, [
    props.refreshAnimation,
    props.p1,
    props.p2,
    props.p3,
    props.p4,
    props.isLoading,
  ]);

  useEffect(() => {
    if (
      props.initialCameraReset !== null &&
      props.p1 &&
      props.p2 &&
      props.p3 &&
      props.p4 &&
      !props.isLoading
    ) {
      initialCameraReset();
    }
  }, [props.initialCameraReset]);

  useEffect(() => {
    if (refreshX && refreshY && refreshZ) {
      setRefreshX(false);
      setRefreshY(false);
      setRefreshZ(false);
      setTimeout(() => {
        zoomIn();
      }, 500);
    }
  }, [refreshX, refreshY, refreshZ]);

  return (
    <>
      {/* <div className="weee">Weeee</div> */}
      <ambientLight intensity={0.1} />
      <spotLight intensity={0.8} position={[300, 300, 400]} />
      <orbitControls ref={controls} args={[camera, domElement]} />
      <Suspense
        fallback={
          <Fallback
            setLoaded={() => {
              props.setP1(true);
              props.setP2(true);
            }}
          />
        }
      >
        <GLTFGROUP
          isLoading={props.isLoading}
          rotation={[0.0019, 0.0019, 0.0019]}
          scaleUpPlanets={scaleUpPlanets}
          goIn={props.goIn}
          navigatingWithArrows={props.navigatingWithArrows}
          goLeft={
            props.sidePopup == "htf" ||
              props.sidePopup == "contact" ||
              props.sidePopup == "" ||
              props.isSidePopupOpen == null
              ? false
              : true
          }
          isSidePopupOpen={props.isSidePopupOpen}
          sidePopup={props.sidePopup}
          onPointerDown2={() => {
            initialCameraReset();
            props.setSidePopup("contact");
            props.setMenuOpen(true);
            props.setNavigatingWithArrows(false);
          }}
          onPointerDown1={(e) => {
            initialCameraReset();
            props.setMenuOpen(true);
            props.setSidePopup("htf");
            props.setNavigatingWithArrows(false);
          }}
        />
      </Suspense>

      <Suspense fallback={<Fallback setLoaded={() => props.setP3(true)} />}>
        <GLTFModal
          goIn={props.goIn}
          path="/gltf/MarblePlanet.gltf"
          scaleUpPlanets={scaleUpPlanets}
          isLoading={props.isLoading}
          maxScale={3}
          position={[-1.2, 0.8, 6 ]}
          aniamtePosition={[
            window.innerWidth > 1200
              ? 1.8 * (window.innerWidth / window.innerHeight)
              : 1.65 * (window.innerWidth / window.innerHeight),
            0,
            0,
          ]}
          animatePlanet={props.sidePopup == "wtf" && props.isSidePopupOpen}
          goLeft={props.sidePopup !== "wtf"}
          isSidePopupOpen={props.isSidePopupOpen}
          navigatingWithArrows={props.navigatingWithArrows}
          sidePopup={props.sidePopup}
          rotation={[0, 0, 0]}
          rotationSpeed={[0, 0.0019, 0]}
          planetType="wtf"
          onPointerDown={() => {
            initialCameraReset();
            props.setSidePopup("wtf");
            props.setMenuOpen(true);
            props.setNavigatingWithArrows(false);
          }}
        />
      </Suspense>
      <Suspense fallback={<Fallback setLoaded={() => props.setP4(true)} />}>
        <GLTFModal
          goIn={props.goIn}
          path="/gltf/EggPlanet.gltf"
          scaleUpPlanets={scaleUpPlanets}
          isLoading={props.isLoading}
          maxScale={5}
          position={[2, 1, 3]}
          rotation={[0.4, 0, 0]}
          aniamtePosition={[
            window.innerWidth > 1400
              ? 2.17 * (window.innerWidth / window.innerHeight)
              : 2 * (window.innerWidth / window.innerHeight),
            0,
            0,
          ]}
          animatePlanet={props.sidePopup == "projects"}
          goLeft={props.sidePopup !== "projects" || !props.isSidePopupOpen}
          isSidePopupOpen={props.isSidePopupOpen}
          rotationSpeed={[0, 0.0019, 0]}
          sidePopup={props.sidePopup}
          planetType="projects"
          navigatingWithArrows={props.navigatingWithArrows}
          onPointerDown={() => {
            initialCameraReset();
            props.setMenuOpen(true);
            props.setSidePopup("projects");
            props.setNavigatingWithArrows(false);
          }}
        />
      </Suspense>
    </>
  );
};
export default Scene;
